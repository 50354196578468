/* nunito-200normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito ExtraLight'),
    local('Nunito-ExtraLight'), 
    url('./files/nunito-latin-ext-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-200italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito ExtraLight Italic'),
    local('Nunito-ExtraLightItalic'), 
    url('./files/nunito-latin-ext-200-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-200-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-300normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light'),
    local('Nunito-Light'), 
    url('./files/nunito-latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-300italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light Italic'),
    local('Nunito-LightItalic'), 
    url('./files/nunito-latin-ext-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-400normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular'),
    local('Nunito-Regular'), 
    url('./files/nunito-latin-ext-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-400italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Italic'),
    local('Nunito-Italic'), 
    url('./files/nunito-latin-ext-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold'),
    local('Nunito-SemiBold'), 
    url('./files/nunito-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold Italic'),
    local('Nunito-SemiBoldItalic'), 
    url('./files/nunito-latin-ext-600-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-600-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold'),
    local('Nunito-Bold'), 
    url('./files/nunito-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold Italic'),
    local('Nunito-BoldItalic'), 
    url('./files/nunito-latin-ext-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-800normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold'),
    local('Nunito-ExtraBold'), 
    url('./files/nunito-latin-ext-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-800italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold Italic'),
    local('Nunito-ExtraBoldItalic'), 
    url('./files/nunito-latin-ext-800-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-800-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-900normal - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black'),
    local('Nunito-Black'), 
    url('./files/nunito-latin-ext-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-900italic - latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black Italic'),
    local('Nunito-BlackItalic'), 
    url('./files/nunito-latin-ext-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/nunito-latin-ext-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
